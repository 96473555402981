<template>
  <section class="add-amenities-slider">
    <div class="row flex-grow">

      <Loader :loading="showLoader" />
      <div class="col-12 grid-margin">
        <div class="card">
          <h4 class="card-title border-bottom p-3">Broker Details</h4>
          <div class="card-body row">

            <!-- Broker details -->
            <div class="col-md-12">
              <p> Company Name: <b>{{ Broker.companyName }}</b> </p>
              <p> Company Address : <b>{{ Broker.companyAddress }}</b> </p>
              <p> Company Trade License Number : <b>{{ Broker.companyTradeLicenseNo }}</b> </p>
              <p> Company ORN <b>{{ Broker.companyORN }}</b> </p>
              <p> Company TRN : <b>{{ Broker.companyTRN }}</b> </p>
              <p> Company Landline Number : <b>{{ Broker.CompanyContryCode }} {{ Broker.companyLandlineNumber }}</b>
              </p>
              <br>
              <p> Signatory Name and Surname : <b>{{ Broker.signatorySurname }}</b> </p>
              <p> Signatory Email Address : <b>{{ Broker.signatoryEmail }}</b> </p>
              <p> Signatory Mobile Number : <b>{{ Broker.signatoryContryCode }} {{ Broker.signatoryNumber }}</b> </p>
              <br>
              <p> Finance Person Name and Surname : <b>{{ Broker.financePersonSurname }}</b> </p>
              <p> Finance Person Email Address : <b>{{ Broker.financePersonEmail }}</b> </p>
              <p> Finance Person Mobile Number : <b>{{ Broker.financePersonContryCode }} {{ Broker.financePersonNumber
                  }}</b> </p>
              <br>
              <p> Administrative Person Name and Surname : <b>{{ Broker.administrativePersonSurname }}</b> </p>
              <p> Administrative Person Email Address : <b>{{ Broker.administrativePersonEmail }}</b> </p>
              <p> Administrative Person Mobile Number : <b>{{ Broker.administrativePersonContryCode }} {{
                Broker.administrativePersonNumber
                  }}</b> </p>
              <p> Agreement & NOC signing : <b>{{ Broker.agreementNOC }} </b> </p>
              <br>
            </div>

            <!-- Company Trade License -->
            <div class="col-md-3 pr-2">
              <p style="font-size: 12px;">Company Trade License</p>
              <div class="image-container w-100">
                <template v-if="isPDF(Broker.companyTradeLicense)">
                  <img src="@/assets/images/pdf.webp" class="img-fluid mb-4 w-100 main-img"
                    @click="openModal('companyTradeLicense')" style="cursor: pointer;" alt="PDF Thumbnail" />
                </template>
                <template v-else>
                  <img :src="`${imageURL}/${Broker.companyTradeLicense}`" class="img-fluid mb-4 w-100 main-img"
                    @click="openModal('companyTradeLicense')" style="cursor: pointer;" alt="Image Preview" />
                </template>

                <!-- Modal for Company Trade License -->
                <div v-if="activeModal === 'companyTradeLicense'" class="modal-overlay" @click.self="closeModal">
                  <div class="modal-content">
                    <span class="close-button" @click="closeModal">&times;</span>
                    <template v-if="isPDF(Broker.companyTradeLicense)">
                      <iframe :src="`${imageURL}/${Broker.companyTradeLicense}`" class="pdf-viewer" width="100%"
                        height="500px" frameborder="0"></iframe>
                    </template>
                    <template v-else>
                      <img :src="`${imageURL}/${Broker.companyTradeLicense}`" class="img-fluid" alt="Image Display" />
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <!-- Memorandum of Association -->
            <div class="col-md-3 pl-2 pr-2">
              <p style="font-size: 12px;">Memorandum of Association</p>
              <div class="image-container w-100">
                <template v-if="isPDF(Broker.memorandumofAssociation)">
                  <img src="@/assets/images/pdf.webp" class="img-fluid mb-4 w-100 main-img"
                    @click="openModal('memorandumofAssociation')" style="cursor: pointer;" alt="PDF Thumbnail" />
                </template>
                <template v-else>
                  <img :src="`${imageURL}/${Broker.memorandumofAssociation}`" class="img-fluid mb-4 w-100 main-img"
                    @click="openModal('memorandumofAssociation')" style="cursor: pointer;" alt="Image Preview" />
                </template>

                <!-- Modal for Memorandum of Association -->
                <div v-if="activeModal === 'memorandumofAssociation'" class="modal-overlay" @click.self="closeModal">
                  <div class="modal-content">
                    <span class="close-button" @click="closeModal">&times;</span>
                    <template v-if="isPDF(Broker.memorandumofAssociation)">
                      <iframe :src="`${imageURL}/${Broker.memorandumofAssociation}`" class="pdf-viewer" width="100%"
                        height="500px" frameborder="0"></iframe>
                    </template>
                    <template v-else>
                      <img :src="`${imageURL}/${Broker.memorandumofAssociation}`" class="img-fluid"
                        alt="Image Display" />
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <!-- Memorandum of Association -->
            <div class="col-md-3 pl-2 pr-3">
              <p style="font-size: 12px;">Personal Documentation for Signatory </p>
              <div class="image-container w-100">
                <template v-if="isPDF(Broker.personalDocumentation)">
                  <img src="@/assets/images/pdf.webp" class="img-fluid mb-4 w-100 main-img"
                    @click="openModal('personalDocumentation')" style="cursor: pointer;" alt="PDF Thumbnail" />
                </template>
                <template v-else>
                  <img :src="`${imageURL}/${Broker.personalDocumentation}`" class="img-fluid mb-4 w-100 main-img"
                    @click="openModal('personalDocumentation')" style="cursor: pointer;" alt="Image Preview" />
                </template>

                <!-- Modal for Memorandum of Association -->
                <div v-if="activeModal === 'personalDocumentation'" class="modal-overlay" @click.self="closeModal">
                  <div class="modal-content">
                    <span class="close-button" @click="closeModal">&times;</span>
                    <template v-if="isPDF(Broker.personalDocumentation)">
                      <iframe :src="`${imageURL}/${Broker.personalDocumentation}`" class="pdf-viewer" width="100%"
                        height="500px" frameborder="0"></iframe>
                    </template>
                    <template v-else>
                      <img :src="`${imageURL}/${Broker.personalDocumentation}`" class="img-fluid" alt="Image Display" />
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <!-- RERA Certificate -->
            <div class="col-md-3 pl-2">
              <p style="font-size: 12px;">RERA Certificate for the Company</p>
              <div class="image-container w-100">
                <template v-if="isPDF(Broker.RERACertificate)">
                  <img src="@/assets/images/pdf.webp" class="img-fluid mb-4 w-100 main-img" @click="openModal('RERACertificate')"
                    style="cursor: pointer;" alt="PDF Thumbnail" />
                </template>
                <template v-else>
                  <img :src="`${imageURL}/${Broker.RERACertificate}`" class="img-fluid mb-4 w-100 main-img"
                    @click="openModal('RERACertificate')" style="cursor: pointer;" alt="Image Preview" />
                </template>

                <!-- Modal for RERA Certificate -->
                <div v-if="activeModal === 'RERACertificate'" class="modal-overlay" @click.self="closeModal">
                  <div class="modal-content">
                    <span class="close-button" @click="closeModal">&times;</span>
                    <template v-if="isPDF(Broker.RERACertificate)">
                      <iframe :src="`${imageURL}/${Broker.RERACertificate}`" class="pdf-viewer" width="100%"
                        height="500px" frameborder="0"></iframe>
                    </template>
                    <template v-else>
                      <img :src="`${imageURL}/${Broker.RERACertificate}`" class="img-fluid" alt="Image Display" />
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <!-- Memorandum of Association -->
            <div class="col-md-4">
              <p>RERA Brokers card </p>
              <div class="image-container w-100">
                <template v-if="isPDF(Broker.RERABrokerscard)">
                  <img src="@/assets/images/pdf.webp" class="img-fluid mb-4 w-100 main-img" @click="openModal('RERABrokerscard')"
                    style="cursor: pointer;" alt="PDF Thumbnail" />
                </template>
                <template v-else>
                  <img :src="`${imageURL}/${Broker.RERABrokerscard}`" class="img-fluid mb-4 w-100 main-img"
                    @click="openModal('RERABrokerscard')" style="cursor: pointer;" alt="Image Preview" />
                </template>

                <!-- Modal for Memorandum of Association -->
                <div v-if="activeModal === 'RERABrokerscard'" class="modal-overlay" @click.self="closeModal">
                  <div class="modal-content">
                    <span class="close-button" @click="closeModal">&times;</span>
                    <template v-if="isPDF(Broker.RERABrokerscard)">
                      <iframe :src="`${imageURL}/${Broker.RERABrokerscard}`" class="pdf-viewer" width="100%"
                        height="500px" frameborder="0"></iframe>
                    </template>
                    <template v-else>
                      <img :src="`${imageURL}/${Broker.RERABrokerscard}`" class="img-fluid" alt="Image Display" />
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <p>Company Tax Reference Number Certificate </p>
              <div class="image-container w-100">
                <template v-if="isPDF(Broker.companyTax)">
                  <img src="@/assets/images/pdf.webp" class="img-fluid mb-4 w-100 main-img" @click="openModal('companyTax')"
                    style="cursor: pointer;" alt="PDF Thumbnail" />
                </template>
                <template v-else>
                  <img :src="`${imageURL}/${Broker.companyTax}`" class="img-fluid mb-4 w-100 main-img"
                    @click="openModal('companyTax')" style="cursor: pointer;" alt="Image Preview" />
                </template>

                <!-- Modal for Memorandum of Association -->
                <div v-if="activeModal === 'companyTax'" class="modal-overlay" @click.self="closeModal">
                  <div class="modal-content">
                    <span class="close-button" @click="closeModal">&times;</span>
                    <template v-if="isPDF(Broker.companyTax)">
                      <iframe :src="`${imageURL}/${Broker.companyTax}`" class="pdf-viewer" width="100%" height="500px"
                        frameborder="0"></iframe>
                    </template>
                    <template v-else>
                      <img :src="`${imageURL}/${Broker.companyTax}`" class="img-fluid" alt="Image Display" />
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <p>Company Tax Reference Number Certificate</p>
              <div class="image-container w-100">
                <template v-if="isPDF(Broker.bankReference)">
                  <img src="@/assets/images/pdf.webp" class="img-fluid mb-4 w-100 main-img" @click="openModal('bankReference')"
                    style="cursor: pointer;" alt="PDF Thumbnail" />
                </template>
                <template v-else>
                  <img :src="`${imageURL}/${Broker.bankReference}`" class="img-fluid mb-4 w-100 main-img"
                    @click="openModal('bankReference')" style="cursor: pointer;" alt="Image Preview" />
                </template>

                <!-- Modal for Memorandum of Association -->
                <div v-if="activeModal === 'bankReference'" class="modal-overlay" @click.self="closeModal">
                  <div class="modal-content">
                    <span class="close-button" @click="closeModal">&times;</span>
                    <template v-if="isPDF(Broker.bankReference)">
                      <iframe :src="`${imageURL}/${Broker.bankReference}`" class="pdf-viewer" width="100%"
                        height="500px" frameborder="0"></iframe>
                    </template>
                    <template v-else>
                      <img :src="`${imageURL}/${Broker.bankReference}`" class="img-fluid" alt="Image Display" />
                    </template>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import Loader from "../components/Loader";
import { contactApi } from "../api";
const imageURL = process.env.VUE_APP_BASE_URL;

export default {
  name: 'add-banner',
  components: {
    Loader,
  },
  data() {
    return {
      imageURL,
      showLoader: false,
      Broker: {}, // Corrected variable name
      activeModal: null, // Track the currently open modal
    };
  },
  mounted() {
    this.getBrokerDetails();
  },
  methods: {
    isPDF(fileName) {
      return fileName.toLowerCase().endsWith('.pdf');
    },
    async getBrokerDetails() {
      try {
        this.showLoader = true;
        const { data } = await contactApi.getBrokerById(this.$route.params.id);
        this.Broker = data.data.data;
      } catch (error) {
        console.error('Error fetching broker details:', error);
      } finally {
        this.showLoader = false;
      }
    },
    openModal(modalName) {
      this.activeModal = modalName;
    },
    closeModal() {
      this.activeModal = null;
    },
  },
};
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  padding: 10px;
  border-radius: 8px;
  max-width: 750px;
  overflow-y: auto;
  max-height: 90vh;
  position: relative;
}
.col-md-3 .image-container .main-img{
  max-width: 200px;
  overflow: hidden;
  object-fit: cover;
}
.col-md-4 .image-container .main-img{
  max-width: 250px;
  overflow: hidden;
  object-fit: cover;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 5px 10px;
  background-color: #fff;
}
</style>
